// Brand color
$beel-color-brand: #00D880;
// Card color
$beel-color-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.04);
// Contextual colors
$beel-color-primary-base: #008cbd;
$beel-color-primary-dark: #007da9;
$beel-color-primary-base: $beel-color-brand;
$beel-color-primary-dark: #001F32;
$beel-color-success-base: #50c892;
$beel-color-success-dark: #47af80;
$beel-color-danger-base: #db5353;
$beel-color-warning-base: #ff9e7e;

// Border colors
$beel-color-border-outside: #d8dee5;
$beel-color-border-outside: #e1e5f1;
$beel-color-border-inside: #e9edf3;
$beel-color-border-dark: #222f3e;
$beel-color-border-uploadbox: #c2c9dd;

// Background colors
$beel-color-bg-body: #f3f4f6;
$beel-color-bg-base: #fff;
$beel-color-bg-hover: #f2f6fa;
$beel-color-bg-disabled: #e9edf3;
$beel-color-bg-inactive: #e9edf3;
$beel-color-bg-linear-gradient: linear-gradient(to right, #008cbd 0%, #00a6bd 100%);
$beel-color-bg-white: #e5f4f7;
$beel-color-icon-bg: #ffffff;
$beel-color-bg-secondary: #00384c;
$beel-color-bg-pb: linear-gradient(to right, #008cbd 50%, #00a6bd 100%);
$beel-color-dark-modal: #05152E;
$beel-color-bg-grey: #EFF1F8;
$beel-color-bg-separator: #E5E7F0;

// Text colors
$beel-color-text-base: #222f3e;
$beel-color-text-primary: $beel-color-primary-base;
$beel-color-text-label: #a6afca;
$beel-color-text-disabled: #9aa5b5;
$beel-color-text-contrast: #ffffff;
$beel-color-text-blue: #008cbc;
$beel-color-text-secondary: #00384c;
$beel-color-text-label-secondary: #a6afca;
$beel-color-text-separator: #BECADC;
$beel-color-text-login-green: #00374C;

// Placeholder colors
$beel-color-placeholder-base: #e1e5f1;
$beel-color-placeholder-transition: #d8dee5;

// Icon colors
$beel-color-icon: #9aa5b5;
$beel-color-chevron: #a6afca;
$beel-color-arrow: #a6afca;

// Shadow colors
$beel-color-shadow-base: rgba(0, 0, 0, 0.05);

// Nav colors
$beel-color-nav-active: rgba(0, 88, 167, 0.05);
$beel-color-nav-overlay: rgba(0, 0, 0, 0.15);

body {
    color: $beel-color-primary-dark;
}

.vwui-button,
.vwui-button.sc-vwui-button {
    --color: #{$beel-color-primary-dark}
}

.vwui-button.is-primary {
    background: $beel-color-primary-dark;
}

.vwui-button.is-primary.vwui-button--disabled {
    background: $beel-color-primary-dark;
    opacity: 0.32;
}

.vwui-button.is-secondary.sc-vwui-button,
.vwui-button.is-secondary {
    --bg: transparent;
    border-color: $beel-color-border-outside;
    color: $beel-color-primary-dark;

    &::before {
        background: transparent;
    }

    &:hover {
        border-color: $beel-color-brand;
    }
}

.vwui-btn-option__input:checked + .vwui-btn-option__btn {
    --shadow-focus: #{$beel-color-brand};
    color: $beel-color-bg-base;
    background: $beel-color-brand;
}

.vwui-btn-option__btn:hover {
    color: $beel-color-brand;
}

vwui-checkbox,
.vwui-checkbox__input.sc-vwui-checkbox:checked ~ .vwui-checkbox__control.sc-vwui-checkbox,
.vwui-checkbox--indeterminate.sc-vwui-checkbox .vwui-checkbox__control.sc-vwui-checkbox {
    --bg-checked: #{$beel-color-brand};
}

vwui-nav-item .badge.sc-vwui-badge {
    background: $beel-color-primary-dark;
}

.badge.sc-vwui-badge {
    background: $beel-color-bg-grey;
}

.app-login-overlay__shape {
    z-index: 1;
    position: absolute;
    max-width: 188px;
    top: 0;
    right: 0;
}

.app-login-overlay__button {
    border: 0;
}

.app-login-overlay__backdrop {
    animation: none;
}

.choice-group {
    input:checked ~ .choice-item__label {
        --radio-inner-scale: 1;
        --radio-outer-border-color: #{$beel-color-brand};
        --radio-outer-bg: #{$beel-color-brand};

        border-color: $beel-color-brand;
    }
}

.drag-drop-overlay {
    background: rgba($beel-color-dark-modal, 0.9);
}

.drag-drop-overlay__button {
    background: $beel-color-brand;
}

.drag-drop-overlay__button__icon {
    background: $beel-color-brand;
}

#Drag-and-Drop-overlay-v2 {
    fill: $beel-color-bg-base;
}

.empty-media-card__description {
    color: var(--vwui-color-neutral-1);
}

.vwui-file-input {
    .browse-text {
        color: $beel-color-brand;
    }

    .clear {
        color: $beel-color-brand;
        font-size: 1.25rem;
    }
}

.icon-circle {
    background: $beel-color-primary-dark;
    color: $beel-color-bg-base;
}

.media-items-add-btn:hover {
    .icon-circle {
        background: $beel-color-brand;
    }
}

.ng-touched.sc-vwui-input-h,
.sc-vwui-input:not(.disabled):not(.readonly) input.sc-vwui-input:focus,
.sc-vwui-input:not(.disabled):not(.readonly) textarea.sc-vwui-input:focus,
input.sc-vwui-textarea, textarea.sc-vwui-textarea {
    --border-color: #{$beel-color-brand};
    --border-color-hover: #{$beel-color-brand};
    --border-color-focus: #{$beel-color-brand};
}

.media-grid__empty-state {
    &__message {
        margin-top: 1.5rem;
    }
}

.media-items-grid-item__selected {
    border: 1px solid $beel-color-brand;
}

.media-processing__close-btn {
    color: $beel-color-primary-dark;
    &:hover {
        color: $beel-color-brand;
    }
}

.menu__logo {
    --width: 136px;
    --height: auto;
}

.vwui-nav-item--menu-visible.sc-vwui-nav-item,
.is-active.sc-vwui-nav-item-h .vwui-nav-item.sc-vwui-nav-item,
.vwui-nav-item--menu-visible.sc-vwui-nav-item,
.vwui-nav-item--menu-visible.sc-vwui-nav-item:hover,
.vwui-nav-item--menu-visible.sc-vwui-nav-item:focus-visible{
    color: $beel-color-primary-dark;
    --icon-color: #{$beel-color-brand};
}

.vwui-container.sc-vwui-search input.sc-vwui-search:focus~.search-icon.sc-vwui-search {
    color: $beel-color-brand;
}

.ng-select.ng-select-opened > .ng-select-container,
.ng-select.ng-select-focused:not(.ng-select-opened) > .ng-select-container,
.sc-vwui-search:not(.disabled):not(.readonly) input.sc-vwui-search:focus,
.sc-vwui-search:not(.disabled):not(.readonly) textarea.sc-vwui-search:focus{
    border-color: $beel-color-brand;
}

.ng-select .ng-clear-wrapper .ng-clear,
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon {
    color: $beel-color-brand;

}

.ng-select.ng-select-opened > .ng-select-container .ng-arrow {
    border-color: transparent transparent $beel-color-brand;
}

// Arrow on focused select when not opened
.ng-select.ng-select-focused:not(.ng-select-opened)>.ng-select-container .ng-arrow {
    border-color: $beel-color-brand transparent transparent;
}

.user-info__initials {
    background: $beel-color-primary-dark;
    font-size: var(--vwui-size-font-xs);
    font-weight: 700;
    line-height: 1;
}

.beel-search .beel-input:focus ~ .beel-search__icon app-icon {
    --icon-fill: #{$beel-color-brand};
}
